import React from 'react';
import styled from '@emotion/styled';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import RouterTracker from '../../components/RouterTracker';
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';

import ThemeContext from '../../context/ThemeContext';

var tinycolor = require('tinycolor2');

const AuthorsStyles = styled.div(
  (props) => `
  padding: ${props.theme.size.base * 13}px 
    ${props.theme.size.base * 4}px 
    ${props.theme.size.base * 4}px;
  ${
    (props.breakpoints.lg || props.breakpoints.xl) &&
    `
   padding: ${props.theme.size.base * 8}px;
  `
  };
  
  .body {
    position: relative;
    z-index: 500;
    max-width: ${props.theme.size.base * 128}px;
    margin: 0 auto;
  }
  .authorlist {
    display: flex;
    flex-wrap: wrap;
    margin: ${props.theme.size.base * -1}px;
    > a {
      flex: 1 0 100%;
      text-decoration: none;
      ${
        (props.breakpoints.lg || props.breakpoints.xl) &&
        `
        flex: 1 0 calc(100% / 3);
        `
      };
    }
  }
`
);
const AuthorCard = styled.div(
  (props) => `
  display: flex;
  align-items: center;
  margin: ${props.theme.size.base * 1}px;
  padding: ${props.theme.size.base * 2}px;
  border-radius:  ${props.theme.size.base}px;
  ${
    props.theme.dark
      ? `
      background: ${props.theme.color.tertiary[95]};
      background: linear-gradient(
        30deg,
        ${props.theme.color.background[10]},
        ${props.theme.color.background[20]}
      );
    `
      : `
      background: ${props.theme.color.tertiary[95]};
      background: linear-gradient(
        30deg,
        ${props.theme.color.background[90]},
        ${props.theme.color.background[100]}
      );
    `
  };
  transition: background 450ms ease;
  .author-image {
    background: ${props.theme.color.background[80]};
    width: ${props.theme.size.base * 8}px;
    height: ${props.theme.size.base * 8}px;
    flex-shrink: 0;
    border-radius: 999px;
    overflow: hidden;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
  .author-info {
    flex-grow: 1;
    padding-left: ${props.theme.size.base * 2}px;
    max-width: ${props.theme.size.base * 72}px;
  }
  .author-name {
    display: flex;
    align-items: center;
    font-size: ${props.theme.size.fontSize[4]};
    line-height: ${props.theme.size.lineHeight[4]};
    color: ${props.theme.color.foreground[100]};
    margin-bottom: ${props.theme.size.base * 0.5}px;
    transition: color 450ms ease;
    &:hover {
      color: ${props.theme.color.tertiary[60]};
    }
    span {
      display: inline-block;
      margin-left: auto;
      padding: 0  ${props.theme.size.base * 1.5}px;
      border-radius: 999px;
      font-size: ${props.theme.size.fontSize[2]};
      line-height: ${props.theme.size.lineHeight[2]};
      transition: color 450ms ease. background 450ms ease;
      ${
        props.theme.dark
          ? `
          background: ${props.theme.color.secondary[40]};
          color: ${tinycolor
            .mostReadable(props.theme.color.secondary[40], [
              props.theme.color.grayscale[0],
              props.theme.color.grayscale[100],
            ])
            .toHexString()};
        `
          : `
          background: ${props.theme.color.secondary[95]};
          color: ${tinycolor
            .mostReadable(props.theme.color.secondary[95], [
              props.theme.color.grayscale[0],
              props.theme.color.grayscale[100],
            ])
            .toHexString()};
        `
      };
    }
  }
  .author-title {
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    text-decoration: none;
    color: ${props.theme.color.primary[70]};
    transition: color 450ms ease;
  }
`
);

const AuthorsPage = ({
  data: {
    authors: { nodes: authors },
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
  data,
}) => {
  const breakpoints = useBreakpoint();

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <AuthorsStyles theme={theme} breakpoints={breakpoints}>
          <RouterTracker />
          <Helmet title={`Authors | ${title}`} />
          <div className='body typesetter'>
            <h1 className='title'>Authors</h1>

            <div className='authorlist'>
              {authors.map((author) => (
                <Link
                  to={`/authors/${kebabCase(author.frontmatter.name)}/`}
                  key={`autho-${kebabCase(author.frontmatter.name)}`}
                >
                  <AuthorCard key={author.frontmatter.name} theme={theme}>
                    <div className='author-image'>
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: author.frontmatter.profile_picture,
                          alt: `Avatar image for ${author.frontmatter.name}`,
                        }}
                      />
                    </div>
                    <div className='author-info'>
                      <div className='author-name'>
                        {author.frontmatter.name}
                      </div>
                      {author.frontmatter.job_title && (
                        <div className='author-title'>
                          {author.frontmatter.job_title}
                        </div>
                      )}
                    </div>
                  </AuthorCard>
                </Link>
              ))}
            </div>
          </div>
        </AuthorsStyles>
      )}
    </ThemeContext.Consumer>
  );
};

export default AuthorsPage;

export const authorPageQuery = graphql`
  query AuthorsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___author) {
        fieldValue
        totalCount
      }
    }
    authors: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "author" } } }
    ) {
      nodes {
        frontmatter {
          name
          job_title
          profile_picture {
            childImageSharp {
              gatsbyImageData(width: 300, quality: 60, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;
